import { createContext } from "react";

type WsContextType = {
    appliedMessages: Set<string>;
};

export const WsContext = createContext<WsContextType>({
    appliedMessages: new Set(),
});

export const WsProvider = ({ children }: { children: React.ReactNode }) => {
    return (
        <WsContext.Provider
            value={{
                appliedMessages: new Set(),
            }}
        >
            {children}
        </WsContext.Provider>
    );
};

import { CiEdit } from "react-icons/ci";
import { useState } from "react";
import { FaRegSave, FaRegTrashAlt } from "react-icons/fa";
import { FactorModelType } from "../../../api/schema";
import { useMessages } from "../../../hooks/useMessages";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Spinner from "react-bootstrap/Spinner";
import { useModelApi } from "../../../hooks/api/aws/useModelApi";
import { useBusy } from "../../../hooks/useBusy";

const PortfolioTitle = ({ model }: { model: FactorModelType }) => {
    const { removeModel, updateModel } = useModelApi();
    const [isEditing, setIsEditing] = useState(false);
    const [updateName, setUpdateName] = useState(model.name || "");
    const [isSaving, setIsSaving] = useState(false);
    const { addInfoMessage, addErrorMessage } = useMessages();
    const queryClient = useQueryClient();

    const eraseModel = useMutation({
        mutationFn: async () => {
            if (model && !isSaving) {
                setIsSaving(true);
                queryClient.setQueryData(
                    ["models"],
                    (oldModels: FactorModelType[] | undefined) => {
                        return (oldModels || []).filter(
                            (oldModel: FactorModelType) =>
                                oldModel.modelId !== model.modelId
                        );
                    }
                );
                await removeModel(model.userId || "", model.modelId);
            }
        },
        onSuccess: () => {
            setIsSaving(false);
            addInfoMessage("Portfolio removed", `Portfolio has been removed`);
        },
        onError: () => {
            setIsSaving(false);
            addErrorMessage(
                "Portfolio remove error",
                `Portfolio has not been removed. Try again later`
            );
            queryClient.setQueryData(
                ["models"],
                (oldModels: FactorModelType[] | undefined) => {
                    return [...(oldModels || []), model];
                }
            );
        },
    });

    const changeModel = useMutation({
        mutationFn: async (newTitle: string) => {
            if (model && updateModel && !isSaving) {
                setIsSaving(true);
                await updateModel(model.userId || "", model.modelId, {
                    ...model,
                    name: newTitle,
                });
                setIsEditing(false);
            }
        },
        onSuccess: () => {
            setIsSaving(false);
            addInfoMessage(
                "Portfolio changed",
                `Portfolio name has been changed`
            );
            queryClient.setQueryData(
                ["models"],
                (oldModels: FactorModelType[] | undefined) => {
                    return (oldModels || []).map(
                        (oldModel: FactorModelType) => {
                            if (oldModel.modelId === model.modelId) {
                                return {
                                    ...oldModel,
                                    name: updateName,
                                };
                            }
                            return oldModel;
                        }
                    );
                }
            );
        },
        onError: () => {
            setIsSaving(false);
            addErrorMessage(
                "Portfolio change error",
                `Portfolio name has not been changed. Try again later`
            );
        },
    });

    if (isSaving) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Saving...</span>
            </Spinner>
        );
    }

    return (
        <div className="my-3">
            {isEditing ? (
                <input
                    value={updateName}
                    onChange={(e) => setUpdateName(e.target.value)}
                />
            ) : (
                <h5
                    className="mr-2"
                    style={{
                        display: "inline-block",
                    }}
                >
                    {model.name || "In process..."}
                </h5>
            )}
            {isEditing ? (
                <FaRegSave
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={() => changeModel.mutate(updateName)}
                />
            ) : (
                <CiEdit
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={() => setIsEditing(true)}
                />
            )}
            <FaRegTrashAlt
                style={{
                    cursor: "pointer",
                }}
                className="ms-auto"
                onClick={() => eraseModel.mutate()}
            />
        </div>
    );
};

export default PortfolioTitle;

import moment from "moment";
import { FactorStrategy } from "../../../../api/schema";
import { useFactors } from "../../../../hooks/query/useFactors";
import { toFixed } from "../../../../utils/utils";
import ReactECharts from "echarts-for-react";

const NormalizedMAE = ({ factor }: { factor: FactorStrategy }) => {
    const { useNormalizedMaeOverTimeQuery } = useFactors();
    const modelAccuracyQuery = useNormalizedMaeOverTimeQuery(
        factor.userId,
        factor.PK,
        factor.Factor
    );

    if (modelAccuracyQuery.data) {
        const options = {
            xAxis: {
                type: "time",
            },
            yAxis: {
                type: "value",
                boundaryGap: [0, "100%"],
                min: function (value: any) {
                    return toFixed(value.min - 0.001, 3);
                },
                max: function (value: any) {
                    console.log("max");
                    console.log(value);
                    return toFixed(value.max + 0.001, 3);
                },
            },
            series: [
                {
                    data: Object.keys(modelAccuracyQuery.data).map(
                        (date: any) => [
                            new Date(date),
                            modelAccuracyQuery.data[date],
                        ]
                    ),
                    type: "line",
                    name: "MAE",
                    showSymbol: false,
                },
            ],
            tooltip: {
                trigger: "axis",
                formatter: function (param: any) {
                    return `${moment(param[0].value[0]).format("MMM, YYYY")} <br/>
                        <div style="display:flex; justify-content:space-between"><span style=margin-right:10px>${param[0].marker} MAE</span> <span>${param[0].value[1]}</div>
                        `;
                },
            },
        };
        return <ReactECharts option={options} />;
    }

    return null;
};

export default NormalizedMAE;

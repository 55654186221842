import { Button, Col, Row } from "react-bootstrap";
import { FactorModelType } from "../../../api/schema";
import EmptySlot from "./EmptySlot";
import ModelCard from "./ModelCard";
import { useModelApi } from "../../../hooks/api/aws/useModelApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useBusy } from "../../../hooks/useBusy";
import { useMessages } from "../../../hooks/useMessages";

const UserStrategies = ({ ownModels }: { ownModels: FactorModelType[] }) => {
    const { addInfoMessage, addErrorMessage } = useMessages();
    const queryClient = useQueryClient();
    const { setIsBusy } = useBusy();
    const { cloneModels } = useModelApi();
    const mutation = useMutation({
        mutationFn: async () => {
            setIsBusy(true);
            return await cloneModels();
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["models"] });
            setIsBusy(false);
            addInfoMessage(
                "Portfolio creation",
                "Demo portfolios cloned successfully"
            );
        },
        onError: (error) => {
            console.error(error);
            setIsBusy(false);
            addErrorMessage(
                "Portfolio creation",
                "Demo portfolios cloning failed. Please try again later."
            );
        },
    });

    const emptySlotsCount = 5 - ownModels.length;
    const emptySlots = Array(emptySlotsCount < 0 ? 0 : emptySlotsCount).fill(
        <Col className="my-3">
            <EmptySlot />
        </Col>
    );
    const modelSlots = ownModels.map((model, i) => (
        <ModelCard model={model} key={model.modelId} />
    ));

    return (
        <>
            {ownModels.length === 0 && (
                <Row>
                    <Col sm={12} lg={6}>
                        <Button
                            className="w-100 my-3"
                            style={{ height: "6rem" }}
                            variant="primary"
                            onClick={() => {
                                mutation.mutate();
                            }}
                        >
                            Clone demo portfolios
                        </Button>
                    </Col>
                    <Col className="my-3 h-100">
                        <EmptySlot />
                    </Col>
                </Row>
            )}
            {ownModels.length > 0 && (
                <>
                    {modelSlots.map((slot, i) => (
                        <div key={slot.key}>{slot}</div>
                    ))}
                    {emptySlots.map((slot, i) => (
                        <Col sm={12} lg={6} key={i}>
                            {slot}
                        </Col>
                    ))}
                </>
            )}
        </>
    );
};

export default UserStrategies;

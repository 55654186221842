import { useQueryClient } from "@tanstack/react-query";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useInterval } from "../useInterval";
import { useContext, useEffect, useState } from "react";
import { useMessages } from "../useMessages";
import { User } from "../../context/authContext";
import AuthClientStore from "../../api/authClientStore";
import { WsContext } from "../../context/wsContext";

export const useWS = ({
    onError,
}: {
    userPromise?: Promise<User>;
    onError: (err: any) => Promise<void>;
}) => {
    const queryClient = useQueryClient();
    const { addInfoMessage, addErrorMessage } = useMessages();
    const [isOpened, setIsOpened] = useState(true);
    const [socketUrl, setSocketUrl] = useState<string | null>(
        `${process.env.REACT_APP_WS_ENDPOINT}?token=${AuthClientStore.getAccessToken()}`
    );
    const { appliedMessages } = useContext(WsContext);

    const { lastMessage, sendMessage, readyState } = useWebSocket(
        socketUrl,
        {
            onOpen: () => {
                console.log("WS open");
            },
            onClose: () => console.log("WS close"),
            onError: async (err: any) => {
                await onError(err);
                setSocketUrl(
                    `${process.env.REACT_APP_WS_ENDPOINT}?token=${AuthClientStore.getAccessToken()}`
                );
            },

            share: true,
            retryOnError: true,
            reconnectAttempts: 10,
            reconnectInterval: 5000,
        },
        isOpened
    );

    useInterval(() => {
        if (readyState === ReadyState.OPEN) {
            sendMessage(
                JSON.stringify({
                    action: "sendMessage",
                    data: {
                        type: "PING",
                    },
                })
            );
        }
    }, 1000 * 60);

    useEffect(() => {
        if (lastMessage !== null) {
            const { type, ...data } = JSON.parse(lastMessage.data);
            const uuid = data.uuid;
            if (uuid && appliedMessages.has(uuid)) {
                return;
            } else if (uuid) {
                console.log("APPLY", uuid);
                appliedMessages.add(uuid);
            }

            if (type === "REFRESH") {
                const target = data.target.split("/");
                queryClient.refetchQueries({
                    queryKey: target,
                    type: "all",
                });
                console.log("REFRESH", target);
            } else if (type === "MESSAGE") {
                switch (data.severity) {
                    case "info":
                        addInfoMessage(data.title, data.message);
                        break;
                    case "error":
                        addErrorMessage(data.title, data.message);
                        break;
                }
            }
        }
    }, [
        lastMessage,
        queryClient,
        addErrorMessage,
        addInfoMessage,
        appliedMessages,
    ]);

    useEffect(() => {
        return () => {
            if (readyState === ReadyState.OPEN) {
                setIsOpened(false);
            }
        };
    }, [readyState]);
};

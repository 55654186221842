import { Col, Placeholder, Row } from "react-bootstrap";
import { useModels } from "../../../hooks/query/useModel";
import styles from "./Dashboard.module.scss";

import { useFactors } from "../../../hooks/query/useFactors";
import UserStrategies from "./UserModels";
import TopFactors from "./FactorsPerformance";
import FamaFrench3F from "./FamaFrench3F";
import FamaFrench5F from "./FamaFrench5F";

const Dashboard = () => {
    const {
        useF3FamaFrenchQuery,
        useF5FamaFrenchQuery,
        useTopFactorsQuery,
        useWorstFactorsQuery,
    } = useFactors();
    const { useModelsQuery } = useModels();
    const modelsQuery = useModelsQuery();
    const topFactorsQuery = useTopFactorsQuery();
    const worstFactorsQuery = useWorstFactorsQuery();
    const famaFrench3FQuery = useF3FamaFrenchQuery();
    const famaFrench5FQuery = useF5FamaFrenchQuery();

    return (
        <Row>
            <Col sm="12" lg="7">
                <Row>
                    <div className={styles.header}>
                        Build your stock portfolio/index/ETF based on proven
                        investment indicators.
                    </div>
                </Row>
                <Row>
                    <Col sm="12" className="mb-4">
                        <div className={styles.howTo}>
                            <span>
                                To get you started, we have selected a couple of
                                the top-performing factors and a strategies
                                based on them. You can freely:
                            </span>
                            <span>
                                <ol>
                                    <li>Change the Factor model</li>
                                    <li>
                                        Built the most suitable Investment
                                        strategies for you
                                    </li>
                                    <li>
                                        See the current stock constitution of
                                        your portfolios and track the
                                        performance
                                    </li>
                                </ol>
                            </span>
                        </div>
                    </Col>
                    <Col sm="12" lg="12" className="mb-4">
                        <h3>My strategies and portfolios</h3>
                        <Row className="justify-content-md-center">
                            {modelsQuery.data ? (
                                <UserStrategies
                                    key="userStrategy"
                                    ownModels={modelsQuery.data}
                                />
                            ) : (
                                <Placeholder
                                    key="userPlaceholder"
                                    as="div"
                                    animation="glow"
                                >
                                    <Placeholder
                                        xs={12}
                                        style={{
                                            height: "300px",
                                        }}
                                    />
                                </Placeholder>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col sm="12" lg="5">
                <Row>
                    <h3>Top 5 gainers of the week - S&P 100</h3>
                </Row>
                {topFactorsQuery.data ? (
                    <Row>
                        <TopFactors
                            factors={topFactorsQuery.data}
                            strategy={"admin_sp100_M"}
                        />
                    </Row>
                ) : (
                    <Row>
                        <Placeholder as="div" animation="glow">
                            <Placeholder
                                xs={12}
                                style={{
                                    height: "300px",
                                }}
                            />
                        </Placeholder>
                    </Row>
                )}
                <Row>
                    <h3>Top 5 losers of the week - S&P 100</h3>
                </Row>
                {worstFactorsQuery.data ? (
                    <Row>
                        <TopFactors
                            factors={worstFactorsQuery.data}
                            strategy={"admin_sp100_M"}
                        />
                    </Row>
                ) : (
                    <Row>
                        <Placeholder as="div" animation="glow">
                            <Placeholder
                                xs={12}
                                style={{
                                    height: "300px",
                                }}
                            />
                        </Placeholder>
                    </Row>
                )}
                <Row>
                    <h3>Fama-French 3 Factors - S&P 500</h3>
                </Row>
                {famaFrench3FQuery.data ? (
                    <Row>
                        <Col>
                            <FamaFrench3F data={famaFrench3FQuery.data} />
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Placeholder as="div" animation="glow">
                            <Placeholder
                                xs={12}
                                style={{
                                    height: "300px",
                                }}
                            />
                        </Placeholder>
                    </Row>
                )}
                <Row>
                    <h3>Fama-French 5 Factors - S&P 500</h3>
                </Row>
                {famaFrench5FQuery.data ? (
                    <Row>
                        <Col>
                            <FamaFrench5F data={famaFrench5FQuery.data} />
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Placeholder as="div" animation="glow">
                            <Placeholder
                                xs={12}
                                style={{
                                    height: "300px",
                                }}
                            />
                        </Placeholder>
                    </Row>
                )}
            </Col>
        </Row>
    );
};

export default Dashboard;

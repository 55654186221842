import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { FactorModelType, NewModel } from "../api/schema";
import { useModelApi } from "./api/aws/useModelApi";

export const useRecalculatePortfolio = ({
    model,
    onBeforeMutation,
    handleError,
    handleSuccess,
}: {
    model?: FactorModelType;
    onBeforeMutation?: () => void;
    handleError?: (error: any) => void;
    handleSuccess?: () => void;
}) => {
    const [isBusy] = useState(false);
    const queryClient = useQueryClient();
    const { recalculateModel } = useModelApi();

    const mutation = useMutation({
        mutationFn: async (updatedDate: NewModel) => {
            // setIsBusy(true);
            if (onBeforeMutation) {
                onBeforeMutation();
            }
            if (model) {
                queryClient.setQueryData(["model", "chart", model.modelId], []);
                queryClient.setQueryData(
                    ["model", "returns", model.modelId],
                    {}
                );
                queryClient.setQueryData(
                    ["model", "current-returns", model.modelId],
                    {}
                );
                queryClient.setQueryData(
                    ["model", "drawdown", model.modelId],
                    {}
                );
                queryClient.setQueryData(
                    ["model", "aggregated-returns", model.modelId],
                    {}
                );
                queryClient.setQueryData(["model", "table", model.modelId], {});

                await recalculateModel(
                    model.userId,
                    model.modelId,
                    model.strategyId || "",
                    {
                        startCash: Number(updatedDate.startCash),
                        leverage: updatedDate.leverage,
                        longsShare: updatedDate.longsShare,
                        shortsShare: updatedDate.shortsShare,
                        numberOfStocks: updatedDate.numberOfStocks,
                        backtestPeriod: updatedDate.backtestPeriod,
                        period: updatedDate.period,
                        portfolioConstruction:
                            updatedDate.portfolioConstruction,
                        ascending: updatedDate.ascending,
                    }
                );

                return model;
            }
        },
        onSuccess: (model?: FactorModelType) => {
            // setIsBusy(false);
            if (model) {
                queryClient.refetchQueries({
                    queryKey: ["models"],
                    type: "all",
                });
            }
            if (handleSuccess) {
                handleSuccess();
            }
        },
        onError: (error: any) => {
            // setIsBusy(false);
            if (handleError) {
                handleError(error);
            }
        },
    });

    return {
        recalculatePortfolio: mutation.mutate,
        isBusy,
    };
};
